

.navbartop{
  top:0;
  position: sticky;
  z-index: 1111;
}

.navbar {
    background-color: #010042;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: 100%;
    
    
  }
  
  .menu-bars {
    margin-left: 2rem;
    font-size: 2rem;
    background: none;
  }
  
  .nav-menu {
    background-color: #010042;
    width: 250px;
    height: 100vh;
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: -100%;
    transition: 850ms;
    z-index: 99999;
  }
  
  .nav-menu.active {
    left: 0;
    transition: 350ms;
    z-index: 99999;
  }
  
  .nav-text {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 8px 0px 8px 16px;
    list-style: none;
    height: 50px;
  }
  
  .nav-text a {
    text-decoration: none;
    color: #f5f5f5;
    font-size: 16px;
    width: 95%;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 15px;
    border-radius: 4px;
  }
  
  .nav-text a:hover {
    background-color: rgba(71, 108, 62, 1);
  }
  
  .nav-menu-items {
    width: 100%;
  }
  
  .navbar-toggle {
    background-color: #010042;
    width: 100%;
    height: 80px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    
  }
  
  span {
    margin-left: 16px;
  }

  .buttons button{
    margin-left: 15%;
  }